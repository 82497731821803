<template>
  <input
    class="form-control"
    :class="{'is-invalid': error, 'mb-3': !error}"
    type="text"
    placeholder="Телефон или эл. почта"
    :value="buffer"
    @input="handleInput($event.target.value)"
    aria-describedby="validationFeedback"
  >
  <div v-if="error" id="validationFeedback" class="invalid-feedback mb-3">
    Пожалуйста, введите верный телефон или email
  </div>
</template>

<script setup>
import { mask } from 'maska'
import { defineEmits, defineProps, ref, watch } from 'vue'

const props = defineProps({
  error: Boolean,
  username: String,
})
const emit = defineEmits([
  'update:username',
])
const buffer = ref(props.username)

watch(() => props.username, (value) => {
  if (value) {
    buffer.value = value
  }
})

const handleInput = (value) => {
  buffer.value = value

  const emailCleared = value
    .replace(/\s/g, '')

  const phoneCleared = emailCleared
    .replace('(', '')
    .replace(')', '')
    .replace('+', '')
    .replace(/-/g, '')

  if (/^\d{10,}$/.test(phoneCleared)) {
    const masked = mask(phoneCleared.substr(phoneCleared.length - 10), '+7 (###) ###-##-##')

    if (props.username !== masked) {
      emit('update:username', masked)
    }
  } else if (/^.+@.+\..+$/.test(emailCleared)) {
    emit('update:username', emailCleared)
  } else {
    emit('update:username', null)
  }
}
</script>

<style scoped>

</style>
